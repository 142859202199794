import ReactGA from "react-ga4";
import { gaMeasurementID } from "../config";

ReactGA.initialize(gaMeasurementID);

export const analytics = {
  track(name: string, params?: object) {
    ReactGA.event(name, params);
  },

  page(path?: string, title?: string) {
    const hitType = "pageview";
    ReactGA.send({ hitType, page: path ?? location.pathname, title: title ?? document.title });
  },
};
