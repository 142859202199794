const production = [
  "/article*",
  "/cs/news",
  "/cs/news/*",
  "/dota/news",
  "/dota/news/*",
  "/dota/heroes",
  "/dota/heroes/*",
  "/rl/news*",
  "/supercell/news/*",
  "/quiz/*",
  "/gaming*",
  "/cs-quiz*",
  "/cs/bounty-simulator*",
  "/counter-strikle",
  "/counter-strikle/",
  "/counter-strikle/multiplayer",
  "/counter-strikle/multiplayer/*",
  "/ads-test",
  "/ads-test-2",
];

export const ssrRoutesPatterns = {
  production,
  development: [...production],
  local: [...production],
};
