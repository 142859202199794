import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { init, browserTracingIntegration } from "@sentry/browser";

init({
  enabled: ["production", "development"].includes(import.meta.env.VITE_ENVIRONMENT),
  dsn: "https://49bbea5cad3841b5a182c0f35c2cf52a@o1183601.ingest.sentry.io/6624426",
  integrations: [browserTracingIntegration()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: import.meta.env.VITE_ENVIRONMENT === "production" ? 0.1 : 1.0,
  environment: import.meta.env.VITE_ENVIRONMENT,
  release: import.meta.env.VITE_ENVIRONMENT === "production" ? import.meta.env.VITE_SENTRY_RELEASE : undefined,
  // This only allows errors which occurs from either
  // - https://*.blast.tv,
  // - https://*.blast-dev.tv
  // - https://*.apicdn.sanity.io
  // - https://*.cookiebot.com
  allowUrls: [
    /https?:\/\/(.*\.)?blast\.tv/,
    /https?:\/\/(.*\.)?blast-dev\.tv/,
    /https?:\/\/(.*\.)?apicdn.sanity\.io/,
    /https?:\/\/(.*\.)?cookiebot\.com/,
  ],
  ignoreErrors: ["Non-Error exception captured", "Non-Error promise rejection captured"], // these are not errors with stack traces, and impossible to track down (assume they are from 3rd party scripts)

  beforeSend(event, hint) {
    if (hint.originalException instanceof Error) {
      // ignore "TypeError: Failed to fetch" error from live/ad-manager-bundle
      const error = hint.originalException;
      if (
        !!error.name.match(/TypeError/i)?.length &&
        !!error.message.match(/Failed to fetch/i)?.length &&
        !!error.stack?.match(/ad-manager/i)?.length
      ) {
        return null;
      }
      // ignore errors loading venatusmedia
      if (error.message.includes("edge.venatusmedia.com")) {
        return null;
      }
    }

    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
