import { captureException } from "@sentry/browser";
import { AxiosError } from "axios";
import { z } from "zod";
import { FormEvent, forwardRef, Ref, useEffect, useId, useMemo, useRef, useState } from "react";
import { LoginRequest, RequestResetPasswordRequest, SignupRequest } from "../../../../../api/auth/types";
import { AuthModalState } from "../../../../../auth/AuthModalProvider";
import { LoginResult, RegisterResult } from "../../../../../auth/AuthProvider";
import { IconButton } from "../../../Icon";
import { Input } from "../Input";
import { TeeVeeAnimation } from "../TeeVeeAnimation";
import {
  getCopyTextSpan,
  getDescriptionLinkText,
  getDescriptionText,
  getFederatedLoginErrorMessage,
  getHeaderText,
  getSubmitButtonText,
  getTeeVeeState,
} from "./formCopy";
import {
  BlurredBackground,
  CancelButton,
  CloseButtonWrapper,
  CopyText,
  Description,
  Divider,
  EmailInput,
  EmailLabel,
  FootNote,
  Form,
  Header,
  Label,
  Modal,
  PasswordLabelContainer,
  StyledAnchor,
} from "./styled";
import { Link, useSearchParams } from "react-router";
import googleLogo from "./assets/google_logo.svg";
import AppleLogo from "./assets/apple_logo.svg";
import { apiBaseURL } from "../../../../../config";

const onBackgroundClick = ({
  event,
  onClose = () => undefined,
}: {
  event: React.MouseEvent<HTMLDivElement, MouseEvent>;
  onClose?: () => void;
}) => {
  if (event.target !== event.currentTarget) return;
  event.stopPropagation();
  onClose();
};

export interface AuthModalsProps {
  modalState?: AuthModalState;
  closeModal?: () => void;
  login: (credentials: LoginRequest) => Promise<LoginResult>;
  register: (credentials: SignupRequest) => Promise<RegisterResult>;
  resetPasswordRequest: (payload: RequestResetPasswordRequest) => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
}

const AuthModals = forwardRef(
  (
    {
      modalState = "login",
      closeModal,
      login,
      register,
      resetPasswordRequest,
      resendVerificationEmail,
    }: AuthModalsProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const [state, setState] = useState<AuthModalState>(modalState);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isOptIn, setIsOptIn] = useState(false);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [showError, setShowError] = useState(false);
    const [loginAttempts, setLoginAttempts] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();
    const [signUpError] = useState<string | null>(() => searchParams.get("sign-up-error"));

    useEffect(() => {
      const hasError = searchParams.get("sign-up-error");
      if (hasError) {
        searchParams.delete("sign-up-error");
        setSearchParams(searchParams);
      }
    }, [searchParams, setSearchParams, state]);

    const formRef = useRef<HTMLFormElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const privacyCheckboxRef = useRef<HTMLInputElement>(null);

    const handleLoginResult = (result: LoginResult) => {
      if (result === "success") {
        setLoginAttempts(0);
        return;
      } else if (result === "wrong-credentials") {
        const message = "Incorrect email or password.";
        passwordInputRef.current?.setCustomValidity(message);
        emailInputRef.current?.setCustomValidity(message);
        formRef.current?.reportValidity();
        setShowError(true);
      } else if (result === "user-third-party-register") {
        const message = "Registered with third-party provider. Use their login flow.";
        passwordInputRef.current?.setCustomValidity(message);
        emailInputRef.current?.setCustomValidity(message);
        formRef.current?.reportValidity();
        setShowError(true);
      } else if (result === "not-verified") {
        setState("awaiting-verification-email");
      }
      setLoginAttempts(loginAttempts + 1);
    };

    const handleRegisterResult = (result: RegisterResult) => {
      switch (result) {
        case "success": {
          setState("awaiting-verification-email");
          return;
        }
        case "email-already-registered": {
          emailInputRef.current?.setCustomValidity("Email already exists, try logging in.");
          formRef.current?.reportValidity();
          break;
        }
        case "invalid-password": {
          passwordInputRef.current?.setCustomValidity("Password must be at least 8 characters long.");
          formRef.current?.reportValidity();
          break;
        }
        case "invalid-email": {
          emailInputRef.current?.setCustomValidity("The entered email is invalid.");
          formRef.current?.reportValidity();
        }
      }
    };

    const handleUnknownError = (error: Error) => {
      if (error instanceof AxiosError) {
        captureException(`Unhandled axios error: ${error}`);
      } else {
        captureException(error);
      }
      setShowError(true);
    };

    useEffect(() => {
      setState(modalState);
    }, [modalState]);

    useEffect(() => {
      setIsWaitingForResponse(false);
      emailInputRef.current?.setCustomValidity("");
      passwordInputRef.current?.setCustomValidity("");
    }, [state]);

    const handleSubmit = async (event: FormEvent) => {
      event.preventDefault();
      if (formRef?.current?.reportValidity()) {
        if (["login", "first-login"].includes(state)) {
          setIsWaitingForResponse(true);

          login({
            login: email,
            password,
          })
            .then((result) => {
              handleLoginResult(result);
              setIsWaitingForResponse(false);
            })
            .catch((error) => {
              handleUnknownError(error);
              setIsWaitingForResponse(false);
            });
        } else if (state === "register") {
          let isInvalid = false;
          if (!z.string().email().safeParse(email).success) {
            handleRegisterResult("invalid-email");
            isInvalid = true;
          }

          if (password.length < 8) {
            handleRegisterResult("invalid-password");
            isInvalid = true;
          }
          if (isInvalid) {
            return;
          }

          setIsWaitingForResponse(true);

          register({
            email,
            password,
            isOptIn,
          })
            .then((result) => {
              handleRegisterResult(result);
              setIsWaitingForResponse(false);
            })
            .catch((error: Error) => {
              handleUnknownError(error);
              setIsWaitingForResponse(false);
            });
        } else if (state === "forgot-password") {
          setIsWaitingForResponse(true);

          resetPasswordRequest({ email })
            .then(() => {
              setIsWaitingForResponse(false);
              setState("reset-password-requested");
            })
            .catch((error) => {
              handleUnknownError(error);
              setIsWaitingForResponse(false);
            });
        } else if (state === "ban") {
          closeModal && closeModal();
        }
      }
    };

    const onDescriptionLinkClick = () => {
      if (state === "awaiting-verification-email") {
        resendVerificationEmail().then(() => {
          setState("verification-email-resent");
        });
      } else {
        if (state === "login") {
          setState("register");
        } else if (state === "register") {
          setState("login");
        } else if (state === "reset-password-requested") {
          setState("forgot-password");
        } else {
          setState("login");
        }
      }
    };

    const isFormValid = useMemo(() => {
      // Correctly check validity between swithing between login and register
      if (state === "register") {
        if (!email) return false;
        if (!password) return false;

        return true;
      }

      if (state === "login") {
        if (!email) return false;
        if (!password) return false;

        return true;
      }

      if (state === "forgot-password") {
        if (!email) return false;

        return true;
      }

      return formRef.current?.checkValidity();
    }, [state, email, password]);

    const isSubmitDisabled = useMemo(() => {
      if (state === "ban") return true;
      if (isWaitingForResponse) return true;
      if (!isFormValid) return true;

      return false;
    }, [state, isWaitingForResponse, isFormValid]);

    const emailInputId = useId();
    const passwordInputId = useId();

    return (
      <BlurredBackground onClick={(event) => onBackgroundClick({ event, onClose: closeModal })} ref={ref}>
        <Modal>
          <CloseButtonWrapper onClick={closeModal}>
            <IconButton
              icon="closeCircleFilled"
              onClick={() => {
                closeModal && closeModal();
              }}
              label="Close"
            />
            {/* <Icon icon="closeCircleFilled" /> */}
          </CloseButtonWrapper>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TeeVeeAnimation state={getTeeVeeState(state)} showError={showError} />
            <Header>{getHeaderText(state)}</Header>
            {!["forgot-password", "first-login"].includes(state) && (
              <Description>
                <span className="text-neutral">{getDescriptionText(state)}</span>
                <StyledAnchor onClick={onDescriptionLinkClick}>{getDescriptionLinkText(state)}</StyledAnchor>
              </Description>
            )}
            <Divider />
            {["login", "register"].includes(state) && signUpError && (
              <label className="mb-4 text-center text-error font-style-body-b3">
                {getFederatedLoginErrorMessage(signUpError)}
              </label>
            )}
            {!!getCopyTextSpan(state) && <CopyText>{getCopyTextSpan(state)}</CopyText>}
            {["login", "first-login", "register", "forgot-password"].includes(state) && (
              <>
                <EmailLabel htmlFor={emailInputId}>Email Address</EmailLabel>
                <EmailInput
                  id={emailInputId}
                  ref={emailInputRef}
                  type={state === "login" ? "text" : "email"}
                  placeholder={"Enter your email address"}
                  value={email}
                  onChange={(event) => {
                    emailInputRef.current?.setCustomValidity("");
                    passwordInputRef.current?.setCustomValidity("");
                    setEmail(event.target.value);
                    setShowError(false);
                  }}
                  required
                  autoCapitalize="off"
                  onEnterKeyPressed={() => {
                    if (emailInputRef.current?.reportValidity()) {
                      passwordInputRef.current?.focus();
                    }
                  }}
                  onFocus={() => {
                    emailInputRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                />
              </>
            )}
            {["register", "login", "first-login"].includes(state) && (
              <>
                <PasswordLabelContainer>
                  <Label htmlFor={passwordInputId}>Password</Label>
                  {["login", "first-login"].includes(state) && (
                    <StyledAnchor onClick={() => setState("forgot-password")}>Forgot password?</StyledAnchor>
                  )}
                </PasswordLabelContainer>
                <Input
                  id={passwordInputId}
                  ref={passwordInputRef}
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(event) => {
                    emailInputRef.current?.setCustomValidity("");
                    passwordInputRef.current?.setCustomValidity("");
                    setPassword(event.target.value);
                    setShowError(false);
                  }}
                  required
                  autoCapitalize="off"
                  onEnterKeyPressed={(event) => {
                    if (passwordInputRef.current?.reportValidity()) {
                      if (state === "register") {
                        event.preventDefault();
                        privacyCheckboxRef.current?.focus();
                      } else {
                        handleSubmit(event);
                      }
                    }
                  }}
                />
              </>
            )}
            {state === "register" && (
              <div className="flex gap-3">
                <input
                  checked={isOptIn}
                  onChange={(e) => {
                    setIsOptIn(e.target.checked);
                  }}
                  type="checkbox"
                />
                <span className="text-left font-style-body-b2">
                  I want access to the latest and greatest news on Dota, CS and BLAST.tv as well as pre-sale tickets and
                  exclusive community perks!
                </span>
              </div>
            )}
            {state === "login" && showError && (
              <label className="mb-4 text-error font-style-body-b3">
                You whiffed that shot.. Check your email and password and try again!
              </label>
            )}

            {["login", "first-login", "register", "forgot-password", "ban"].includes(state) && (
              <button disabled={isSubmitDisabled} type="submit" className="button w-full">
                {getSubmitButtonText(state)}
              </button>
            )}
            {state === "register" && (
              <div className="mt-4 px-10 text-center font-style-body-b2">
                <span>{"By continuing, you're agreeing to our "}</span>
                <Link to="/privacy-policy" className="no-underline" target="_blank">
                  <span className="text-center text-primary-100">{" Terms of Use and Privacy policy."}</span>
                </Link>
              </div>
            )}
            {["login", "register"].includes(state) && (
              <div className="flex flex-col">
                <div className="my-5 flex flex-row">
                  <Divider />
                  <label className="mx-3 content-center whitespace-nowrap text-foreground-90 font-style-body-b2">
                    or {state === "register" ? " register with" : " login with"}
                  </label>
                  <Divider />
                </div>
                <div className="flex w-full flex-row justify-center gap-8">
                  <a
                    className="grid size-16 place-items-center rounded bg-white hover:bg-foreground-95"
                    href={`${apiBaseURL}/v1/auth/providers/authorize?redirectUri=${location.href}&provider=google`}
                    rel="noreferrer"
                  >
                    <img src={googleLogo} alt="google" />
                  </a>
                  <a
                    className="grid size-16 place-items-center rounded bg-white hover:bg-foreground-95"
                    href={`${apiBaseURL}/v1/auth/providers/authorize?redirectUri=${location.href}&provider=apple`}
                    rel="noreferrer"
                  >
                    <img src={AppleLogo} alt="apple" />
                  </a>
                </div>
              </div>
            )}
            {state === "forgot-password" && (
              <CancelButton onClick={() => setState("login")} variant="secondary">
                Cancel
              </CancelButton>
            )}
            {["awaiting-verification-email", "verification-email-resent", "reset-password-requested"].includes(
              state,
            ) && <FootNote>If you are experiencing problems, please contact us at support@blast.tv</FootNote>}
          </Form>
        </Modal>
      </BlurredBackground>
    );
  },
);

AuthModals.displayName = "AuthModals";

export { AuthModals };
