// TODO: Resolve eslint issue, move context to a separate file
/* eslint-disable react-refresh/only-export-components */
import { ReactNode, createContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useGetSettings } from "../../../api/data/sanity/getSettings/useGetSettings";
import { useGameId } from "@providers/GameIdProvider/useGameId";
import { Icon } from "../Icon";
import { useAuthModal } from "../../../auth/AuthModalProvider";
import { useAuth } from "../../../auth/AuthProvider/hooks/useAuth";
import { HomeLink } from "./components/HomeLink";
import classNames from "classnames";
import { Logo } from "./components/Logo";
import { GameNav } from "./components/GameNav";
import { NavTopLevelParent } from "./components/NavTopLevelParent";
import { NavTopLevelLink } from "./components/NavTopLevelLink";
import { PointsButton } from "./components/PointsButton";
import { ProfileButton } from "./components/ProfileButton";
import { MobileBurgerMenu } from "./components/MobileBurgerMenu";
import { DotaSearchButton } from "./components/DotaSearchButton";
import { useNavBarContext } from "./useNavBarContext";
import { useLiveNavItem } from "./hooks/useLiveNavItem";
import { useTheaterMode } from "@shared/hooks/useTheaterMode";
import { TournamentTimelineNav } from "./components/TournamentTimelineNav";
import { useGetFlag } from "@data/flagsmith/getFlags/useGetFlag";

function useData() {
  const [isNavTransparent, setIsNavTransparent] = useState(false);
  const { gameId } = useGameId();
  const showDotaSearchButton = useMemo(() => {
    if (gameId !== "dota") return false;

    return true;
  }, [gameId]);

  return {
    isNavTransparent,
    setIsNavTransparent,
    showDotaSearchButton,
  };
}

export const NavBarContext = createContext<ReturnType<typeof useData> | undefined>(undefined);

export const NavBarProvider = ({ children }: { children: ReactNode }) => {
  const data = useData();

  return (
    <NavBarContext.Provider value={data}>
      <NavBar />
      {children}
    </NavBarContext.Provider>
  );
};

export const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { gameId } = useGameId();
  const { openModal } = useAuthModal();
  const { isAuthenticated } = useAuth();
  const { data: settingsData } = useGetSettings();
  const gameNavbarItems = settingsData?.nav;
  const { isNavTransparent, setIsNavTransparent, showDotaSearchButton } = useNavBarContext();
  const { pathname } = useLocation();
  const dotaLiveFlag = useGetFlag("frontend_dota_live");

  useEffect(() => {
    if (isNavTransparent) {
      setIsNavTransparent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleScroll = () => {
    const position = window.scrollY;
    const pos = (24 / 100) * position;
    const percentage = Math.min(pos, 1);

    // Set the opacity of the navbar using javascript
    const navbar = document.getElementById("transparent-navbar");

    if (!navbar) return;

    navbar.style.backgroundColor = `rgb(31 12 25 / ${percentage})`;
  };

  const { isTheaterMode } = useTheaterMode();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { navbarItem: liveNavbarItem } = useLiveNavItem();

  const navbarItems = useMemo(() => {
    if (!gameNavbarItems) return [];

    if (gameId === "cs") return gameNavbarItems.cs;
    if (gameId === "dota") return gameNavbarItems.dota;
    if (gameId === "supercell") return gameNavbarItems.supercell;
    if (gameId === "gaming") return gameNavbarItems.gaming;

    return []; // global home page has custom game nav
  }, [gameId, gameNavbarItems]);

  // Hide the navbar on the clip embed page
  if (pathname.startsWith("/clips/embed")) {
    return null;
  }

  return (
    <nav
      id={isNavTransparent ? "transparent-navbar" : undefined}
      className={classNames(
        "fixed top-0 z-navbar w-screen px-4 transition-all duration-200 ease-in-out max-sd:h-11 md:px-8 sd:px-11",
        {
          "h-[3.75rem]": !isTheaterMode,
          "h-0 overflow-hidden": isTheaterMode,
          "!bg-background-100": !isNavTransparent,
        },
      )}
    >
      <div className="relative m-auto flex h-full max-w-nav flex-nowrap items-center gap-6 max-sd:justify-between">
        <button
          aria-label={isMobileMenuOpen ? "Close Menu" : "Open Menu"}
          className="sd:hidden"
          onClick={() => setIsMobileMenuOpen((current) => !current)}
        >
          <Icon icon={isMobileMenuOpen ? "closeCircled" : "burgerMenu"} className="size-6" />
        </button>
        <HomeLink
          onClick={() => setIsMobileMenuOpen(false)}
          className={classNames(
            "group size-full w-7 shrink-0 border-b-2 transition-all duration-200 ease-in-out max-sd:absolute max-sd:left-1/2 max-sd:top-0 max-sd:max-w-6 max-sd:-translate-x-1/2",
            {
              "opacity-0": isMobileMenuOpen,
              "opacity-100": !isMobileMenuOpen,
              "border-primary-100 max-sd:border-transparent": gameId && pathname === `/${gameId}`,
              "border-transparent": !gameId || pathname !== `/${gameId}`,
            },
          )}
        >
          <Logo className="transition-all group-hover:scale-90" />
        </HomeLink>
        <GameNav className="max-sd:hidden" />
        {(gameId === "cs" || (gameId === "dota" && dotaLiveFlag)) && liveNavbarItem && (
          <NavTopLevelParent
            isLiveOption
            parent={liveNavbarItem}
            className="max-sd:hidden"
            overrideIsActive={pathname.startsWith("/live")}
          />
        )}
        <div
          className={classNames(
            "flex size-full flex-nowrap gap-6 transition-opacity delay-200 duration-300 max-sd:hidden",
          )}
        >
          {navbarItems?.map((item) =>
            item._type === "navbarTournamentsItem" ? (
              <TournamentTimelineNav gameId={gameId} key={item._key} topLevelNavItem={item} />
            ) : item.children ? (
              <NavTopLevelParent key={item._key} parent={item} />
            ) : (
              <NavTopLevelLink key={item._key} navbarItem={item} onClick={() => setIsMobileMenuOpen(false)} />
            ),
          )}
        </div>
        {showDotaSearchButton && <DotaSearchButton className="mr-3 max-sd:hidden" />}
        {isAuthenticated ? (
          <div className="flex items-center gap-2">
            <PointsButton />
            <ProfileButton />
          </div>
        ) : (
          <>
            <div
              className={classNames("flex flex-nowrap items-center gap-1", {
                "max-sd:hidden": !isMobileMenuOpen,
              })}
            >
              <button
                onClick={() => openModal("login")}
                className="button button-tertiary whitespace-nowrap px-3 text-white font-style-navigation-desktop"
              >
                Log in
              </button>
              <button onClick={() => openModal("register")} className="button whitespace-nowrap px-3">
                Join The Community
              </button>
            </div>
            {!isMobileMenuOpen && (
              <button
                className="size-8 rounded-full border border-solid border-white sd:hidden"
                onClick={() => openModal("register")}
                aria-label="Join The Community"
              >
                <Icon icon="anonUser" className="m-auto" />
              </button>
            )}
          </>
        )}
      </div>
      <MobileBurgerMenu
        navbarItems={navbarItems ?? []}
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        className="fixed left-0 top-11 sd:hidden"
      />
    </nav>
  );
};
