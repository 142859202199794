import { Icon } from "@shared/components/Icon";
import classNames from "classnames";
import { Link } from "react-router";

export const DotaSearchButton = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <Link
      className={classNames(
        "hidden items-center gap-4 whitespace-nowrap rounded-full border border-primary-100 bg-background-95 px-4 py-2 lg:flex",
        className,
      )}
      onClick={onClick}
      to="/dota/users"
    >
      <span className="block text-foreground-95 font-style-body-b3">Search user by steam ID</span>
      <Icon icon="magnifyingGlass" />
    </Link>
  );
};
