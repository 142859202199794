import classNames from "classnames";
import { useMemo, useState } from "react";
import { DownArrow } from "../../DownArrow";
import { NavLink } from "../../NavLink";
import { useLocation } from "react-router";
import { ExternalArrow } from "../../ExternalArrow";
import { GroupedNavbarItems, NavbarParentItem } from "@shared/components/Navbar/shared/types";
import { hasActiveChildItem } from "@shared/components/Navbar/shared/helpers";
import { NavbarChildItem } from "@data/sanity/getSettings/schemas/NavbarSchema";

const calculateHeight = (parent: NavbarParentItem) => {
  let height = 0;

  for (const child of parent.children ?? []) {
    if (child._type === "navbarItem") {
      height += 50;
    } else if (child._type === "groupedNavbarItems") {
      // group header: height + margin + ?
      height += 22;

      for (const groupedChild of child.children) {
        if (groupedChild.subText || child.badge) {
          height += 74;
        } else {
          height += 50;
        }
      }
    }
  }

  return height;
};

export const MobileNavParent = ({
  parent,
  isLiveOption,
  className,
  overrideIsActive,
  onNavLinkClick,
}: {
  parent: NavbarParentItem;
  isLiveOption?: boolean;
  className?: string;
  overrideIsActive?: boolean;
  onNavLinkClick?: () => void;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const isActive = useMemo(
    () => overrideIsActive || hasActiveChildItem(parent, location.pathname),
    [parent, location.pathname, overrideIsActive],
  );

  if (parent._type === "navbarTournamentsItem") {
    // Handled separately in TournamentTimelineNav
    return null;
  }

  if (!parent.children) return null;

  return (
    <div className={classNames("w-full", className)}>
      <button
        className={classNames(
          "group flex cursor-pointer content-center items-center gap-1 border-y-2 border-transparent pb-2 transition-all font-style-navigation-mobile",
          {
            "border-b-primary-100": isActive,
          },
        )}
        onClick={() => setDropdownOpen((prev) => !prev)}
      >
        {isLiveOption ? <div className="mr-2 size-2 rounded-full bg-error" /> : null}
        <span>{parent.title}</span>
        <DownArrow
          className={classNames("transition-all", {
            "rotate-180 text-primary-100": dropdownOpen,
            "text-foreground-90": !dropdownOpen,
          })}
        />
      </button>
      <div
        className="flex w-full flex-col overflow-hidden transition-all duration-200 ease-in-out"
        style={{ height: dropdownOpen ? `${calculateHeight(parent)}px` : 0 }}
      >
        {parent.children.map((child, index) => (
          <ChildItem
            navbarItem={child}
            onNavLinkClick={onNavLinkClick}
            key={index}
            className={classNames("transition-all", {
              "opacity-0": !dropdownOpen,
              "opacity-100": dropdownOpen,
            })}
          />
        ))}
      </div>
    </div>
  );
};

const ChildItem = ({
  navbarItem,
  onNavLinkClick,
  className,
}: {
  navbarItem: GroupedNavbarItems | NavbarChildItem;
  onNavLinkClick?: () => void;
  className?: string;
}) => {
  const location = useLocation();
  return (
    <>
      {navbarItem._type === "groupedNavbarItems" ? (
        <GroupedLinks
          groupedItems={navbarItem}
          onNavLinkClick={onNavLinkClick}
          className={classNames("border-y border-transparent last:border-b-background-90", className)}
        />
      ) : (
        <NavLink
          navbarItem={navbarItem}
          onClick={onNavLinkClick}
          className={classNames(
            "flex flex-col gap-2 border-y border-transparent border-t-background-90 py-4 first:border-t-transparent last:border-b-background-90",
            {
              "text-primary-100": navbarItem.route === location.pathname,
              "text-foreground-90": navbarItem.route !== location.pathname,
            },
            className,
          )}
        >
          <div className="font-style-label-l3">
            {navbarItem.title}
            {navbarItem.externalLink && <ExternalArrow className="ml-2" />}
          </div>
        </NavLink>
      )}
    </>
  );
};

const GroupedLinks = ({
  groupedItems,
  onNavLinkClick,
  className,
}: {
  groupedItems: GroupedNavbarItems;
  onNavLinkClick?: () => void;
  className?: string;
}) => {
  const location = useLocation();
  return (
    <div className={className}>
      {groupedItems.headerText && (
        <div className="mt-1 flex items-center gap-3">
          <div className="shrink-0 text-neutral font-style-body-b3">{groupedItems.headerText}</div>
          <div className="h-px w-full flex-1 bg-background-90" />
        </div>
      )}
      {groupedItems.children.map((child, index) => (
        <NavLink
          key={index}
          navbarItem={child}
          onClick={onNavLinkClick}
          className={classNames(
            "flex flex-col gap-2 border-y border-b-background-90 border-t-transparent py-4 last:border-b-transparent",
            {
              "text-primary-100": child.route === location.pathname,
              "text-foreground-90": child.route !== location.pathname,
            },
          )}
        >
          <div className="font-style-label-l3">
            {child.title}
            {child.externalLink && <ExternalArrow className="ml-2" />}
          </div>
          {(groupedItems.badge || child.subText) && (
            <div className="flex items-center gap-2">
              {child.subText && <div className="text-neutral font-style-body-b3">{child.subText}</div>}
              {groupedItems.badge === "live" && (
                <>
                  <div className="size-1 rounded-full bg-error" />
                  <div className="text-error font-style-label-l4">Live</div>
                </>
              )}
            </div>
          )}
        </NavLink>
      ))}
    </div>
  );
};
