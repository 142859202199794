/* eslint-disable tailwindcss/no-custom-classname */
import { captureException } from "@sentry/browser";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense } from "react";
import { BrowserRouter } from "react-router";
import { GoogleReCaptchaProvider } from "@google-recaptcha/react";
import { Routing } from "../Routing";
import { ApiProvider } from "../api/ApiProvider";
import { AuthModalProvider } from "../auth/AuthModalProvider";
import { AuthProvider } from "../auth/AuthProvider";
import { AuthModalsContainer } from "../shared/components/AuthModalsContainer";
import { ErrorBoundary } from "../shared/components/ErrorBoundary";
import { NavBarProvider } from "../shared/components/Navbar";
import { ScrollToTop } from "./components/ScrollToTop";
import { MetaProvider } from "../meta/MetaProvider";
import { AvatarModalProvider } from "../providers/AvatarModal";
import { TheaterModeProvider } from "../providers/TheaterModeProvider";
import { ErrorPage } from "../routes/ErrorPage";
import { AlertProvider } from "../providers/AlertProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PictureInPictureProvider } from "../shared/components/PictureInPictureVideoPlayer";
import { GameIdProvider } from "../providers/GameIdProvider";
import { z } from "zod";
import { HelmetProvider } from "react-helmet-async";
import { recaptchaSiteKey } from "../config";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      // capture all ZodErrors with Sentry
      if (error instanceof z.ZodError) {
        // eslint-disable-next-line no-console
        console.error({ ...error }); // helpful for debugging zod errors
        captureException(error);
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function App() {
  return (
    <GoogleReCaptchaProvider type={"v3"} siteKey={recaptchaSiteKey} explicit={{ badge: "hidden" }}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ApiProvider>
          <AuthModalProvider>
            <AuthProvider>
              <TheaterModeProvider>
                <AvatarModalProvider>
                  <div className="App">
                    <HelmetProvider>
                      <BrowserRouter>
                        <GameIdProvider>
                          <MetaProvider>
                            <AlertProvider>
                              <NavBarProvider>
                                <ErrorBoundary>
                                  <AuthModalsContainer />
                                </ErrorBoundary>
                                <ScrollToTop />
                                <ErrorBoundary fallback={<ErrorPage />}>
                                  <PictureInPictureProvider>
                                    <Suspense>
                                      <Routing />
                                    </Suspense>
                                  </PictureInPictureProvider>
                                </ErrorBoundary>
                              </NavBarProvider>
                            </AlertProvider>
                          </MetaProvider>
                        </GameIdProvider>
                      </BrowserRouter>
                    </HelmetProvider>
                  </div>
                </AvatarModalProvider>
              </TheaterModeProvider>
            </AuthProvider>
          </AuthModalProvider>
        </ApiProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  );
}
