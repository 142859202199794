import { useMemo, useState } from "react";
import classNames from "classnames";
import { DownArrow } from "./DownArrow";
import { Transition } from "@headlessui/react";
import { NavLink } from "./NavLink";
import { useLocation } from "react-router";
import { ExternalArrow } from "./ExternalArrow";
import { GroupedNavbarItems, NavbarParentItem } from "../shared/types";
import { hasActiveChildItem } from "../shared/helpers";

export const NavTopLevelParent = ({
  parent,
  isLiveOption,
  className,
  overrideIsActive,
}: {
  parent: NavbarParentItem;
  isLiveOption?: boolean;
  className?: string;
  overrideIsActive?: boolean;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const isActive = useMemo(
    () => overrideIsActive || hasActiveChildItem(parent, location.pathname),
    [parent, location.pathname, overrideIsActive],
  );

  if (parent._type === "navbarTournamentsItem") {
    // Handled separately in TournamentTimelineNav
    return null;
  }

  if (!parent.children) return null;

  return (
    <div
      className={classNames(
        "relative flex h-full items-center border-y-2 border-transparent",
        {
          "border-b-primary-100": isActive,
        },
        className,
      )}
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
    >
      <>
        <NavLink
          className="group flex h-full cursor-pointer content-center items-center gap-1 font-style-navigation-desktop"
          navbarItem={parent}
          key={parent._key}
        >
          {isLiveOption ? <div className="mr-2 size-2 rounded-full bg-error" /> : null}
          <span className="text-foreground-100 group-hover:text-foreground-95">{parent.title}</span>
          <DownArrow />
        </NavLink>
        <Transition
          as="div"
          show={dropdownOpen}
          enter="transition-all"
          enterFrom="-translate-y-4 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition-all"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="-translate-y-4 opacity-0"
          className="absolute -left-5 top-[56px] z-navbarMenu flex flex-col justify-start rounded-b-small bg-background-100 px-5"
        >
          {parent.children?.map((child, index) =>
            child._type === "groupedNavbarItems" ? (
              <GroupedLinks groupedItems={child} key={index} />
            ) : (
              <NavLink
                navbarItem={child}
                key={index}
                className={classNames(
                  "group flex min-w-24 flex-col gap-2 whitespace-nowrap border-t border-solid border-background-90 py-4 first:border-t-0",
                )}
              >
                <div
                  className={classNames("transition-all font-style-navigation-desktop", {
                    "text-primary-100 group-hover:text-primary-100/90": child.route === location.pathname,
                    "text-foreground-100 group-hover:text-foreground-95": child.route !== location.pathname,
                  })}
                >
                  {child.title}
                  {child.externalLink && <ExternalArrow className="ml-2" />}
                </div>
              </NavLink>
            ),
          )}
        </Transition>
      </>
    </div>
  );
};

const GroupedLinks = ({ groupedItems }: { groupedItems: GroupedNavbarItems }) => {
  return (
    <div>
      {groupedItems.headerText && (
        <div className="mt-1 flex items-center gap-3">
          <div className="shrink-0 text-neutral font-style-body-b3">{groupedItems.headerText}</div>
          <div className="h-px w-full flex-1 bg-background-90" />
        </div>
      )}
      {groupedItems.children.map((child, index) => (
        <NavLink
          navbarItem={child}
          key={index}
          className={classNames(
            "group flex min-w-24 flex-col gap-2 whitespace-nowrap border-b border-solid border-background-90 py-4 last:border-b-0",
          )}
        >
          <div
            className={classNames("transition-all font-style-navigation-desktop", {
              "text-primary-100 group-hover:text-primary-100/90": child.route === location.pathname,
              "text-foreground-100 group-hover:text-foreground-95": child.route !== location.pathname,
            })}
          >
            {child.title}
            {child.externalLink && <ExternalArrow className="ml-2" />}
          </div>
          {(groupedItems.badge || child.subText) && (
            <div className="flex items-center gap-2">
              {child.subText && <div className="text-neutral font-style-body-b3">{child.subText}</div>}
              {groupedItems.badge === "live" && (
                <>
                  <div className="size-1 rounded-full bg-error" />
                  <div className="text-error font-style-label-l4">Live</div>
                </>
              )}
            </div>
          )}
        </NavLink>
      ))}
    </div>
  );
};
