import { ForwardedRef, forwardRef, lazy } from "react";
import { ErrorBoundary } from "../../shared/components/ErrorBoundary";

// imports the remote component:
// eslint-disable-next-line import/no-unresolved
const VideoPlayer = lazy(() => import("videoPlayerModule/VideoPlayer"));

type Props = React.ComponentProps<typeof VideoPlayer>;

const VideoPlayerModule = forwardRef<HTMLVideoElement, Props>(
  (props: Props, forwardedRef: ForwardedRef<HTMLVideoElement>) => {
    return (
      <ErrorBoundary
        fallback={
          <div className="font-style-body-b2">The Video Player is not available right now. Try again in a while.</div>
        }
      >
        <VideoPlayer {...props} ref={forwardedRef} />
      </ErrorBoundary>
    );
  },
);

VideoPlayerModule.displayName = "VideoPlayerModule";

export { VideoPlayerModule };
// eslint-disable-next-line import/no-default-export
export default VideoPlayerModule;
